<template>
  <router-view />
</template>


<script>

export default {
  mounted() {
    // keyword

    if (this._isMobile()) {
        // let url = `http://m.jqy.letgofly.com/#/`
        let url = `https://m.10417.com/#/`

        if (location.hash.replace("#/","")) {
          // url = `http://m.jqy.letgofly.com/#/pages/${location.hash.replace("#/","").split('?')[0]}/${location.hash.replace("#/","")}`
          url = `https://m.10417.com/#/pages/${location.hash.replace("#/","").split('?')[0]}/${location.hash.replace("#/","")}`
        }
        location.href = url
    }
    
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  background-color: #f3f6fa;
  height: 100%;
}
</style>
