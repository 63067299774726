import { post, get, updateAdd } from "./config";

// 登录
export function userLogin(options) {
  return post("/users/login", options);
}

//获取黑名单
export function getBlack(options) {
  return get("/blacklist/getBlacklist_just", options);
}


//获取客服
export function getService(options) {
  return get("/service/list", options);
}





